.quote {
  padding-top: var(--gap-double);
  padding-bottom: var(--gap-double);

  &__author,
  &__about {
    font-style: normal;
    font-size: var(--fz-sm);
  }

  &__author {
    display: block;
    font-weight: bold;
    font-size: var(--fz-sm);
    margin-bottom: var(--gap-half);
  }

  &__line {
    width: 100px;
    height: 5px;
    margin-top: var(--gap-double);
    margin-bottom: var(--gap-double);
    background-color: rgba(0, 43, 66, 0.2);

    .theme-dark & {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  &__content {
    max-width: 600px;
  }
}
