.site-nav {
  a {
    text-decoration: none;
  }

  &__nav {
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }

  @media (--md) {
    &__dropdown-toggle {
      display: none;
    }

    &__nav {
      flex-direction: row;
      justify-content: space-around;
      flex-grow: 1;
      border-bottom: 1px solid var(--grey-light);
      height: var(--header-height);
    }

    &__item {
      flex-grow: 0;
      position: relative;
      padding-left: var(--gap);
      padding-right: var(--gap);
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }

      a {
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.7;
        color: var(--black);
        padding: var(--gap) 0;

        &:hover,
        &:focus {
          opacity: 1;
        }
      }

      & > a {
        padding: var(--gap-double) 0;
        height: var(--header-height);
        border-bottom: 3px solid transparent;
      }

      &.current-menu-item,
      &.current-page-ancestor,
      &.current-page-parent {
        & > a {
          opacity: 1;
          border-bottom-color: var(--green);
        }
      }

      &--dropdown {
        &:hover,
        &:focus-within {
          & > ul {
            transform: scaleY(1);
            visibility: visible;
          }
        }
      }
    }

    &__subitem {
      padding: 0 0.4rem;
    }

    &__subnav {
      visibility: hidden;
      position: absolute;
      width: 300px;
      left: -1rem;
      padding: 1rem;
      z-index: 1;
      background: var(--white);
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
      transform: scaleY(0);
      transition: transform 0.2s ease;
      transform-origin: top;

      a {
        text-align: left;
        justify-content: flex-start;
      }
    }
  }

  @media (--md-max) {
    &__nav {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      background-color: var(--white);
    }

    &__item {
      position: relative;
      border-bottom: 1px solid var(--grey-xlight);
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      a {
        padding: 20px 50px 20px 30px;
        display: block;
        width: 100%;
        text-align: left;
        opacity: 0.7;
      }

      &.current-menu-item {
        & > a {
          opacity: 1;
        }
      }

      &--dropdown {
        & > a {
          width: calc(100% - 54px);
        }
      }

      &--expanded {
        & > a {
          background-color: var(--grey-xxlight);
        }

        & > ul {
          width: 100%;
          max-height: 100%;
          height: auto;
        }
      }
    }

    &__dropdown-toggle {
      cursor: pointer;
      background: none;
      flex-shrink: 0;
      align-self: stretch;
      appearance: none;
      border: none;
      padding: 0;

      &[aria-expanded="true"] {
        .vertical {
          opacity: 0;
        }
        & + ul {
          width: 100%;
          max-height: 100%;
          height: auto;
        }
      }
    }

    &__subnav {
      overflow: hidden;
      max-height: 0;
      height: 0;
      width: 100%;
      padding-left: var(--gap-double);
      background-color: var(--grey-xxlight);
    }
  }
}
