/* WORDPRESS GUTENBERG BLOCKS STYLING */

.wp-block {
  &-image {
    figure {
      /* We float the img inside the figure that has .alignleft applied.
      This makes the .alignleft element have the same main column width as text.
      What makes the float work is that the figure collapses into a zero height element */
      &.alignleft {
        display: block;
        float: left;
        margin-right: var(--gap);
      }

      &.alignright {
        display: block;
        float: right;
        margin-left: var(--gap);
      }

      /* For captions, we display the figure itself as a table.
      This ensures the figcaption will retain the same width as the image,
      which is important when the caption is long. */
      &.alignleft,
      &.alignright {
        display: table;
      }

      &.alignleft figcaption,
      &.alignright figcaption {
        display: table-caption;
        caption-side: bottom;
      }

      &.alignright figcaption,
      &.alignleft figcaption {
        font-size: 75%;
        margin-bottom: var(--gap);
      }
    }

    & > figcaption {
      font-size: var(--fz-sm);
      margin-top: var(--gap-half);
      margin-bottom: var(--gap);
      text-align: center;
    }

    &.alignfull {
      margin-bottom: 0;
    }
  }

  &-quote {
    background-color: var(--blue-dark);
    border-left: 4px solid var(--blue-dark);
    padding: var(--gap-double);
    margin-top: var(--gap);
    margin-bottom: var(--gap);
  }

  &-media-text {
    padding: var(--gap) 0;
    margin-top: var(--gap);
    margin-bottom: var(--gap);
  }

  &-video {
    margin-top: var(--gap);
    margin-bottom: var(--gap);
    margin-left: auto !important;
    margin-right: auto !important;
  }

  &-file {
    padding: var(--gap) 0;
    margin-top: var(--gap);
    margin-bottom: var(--gap);

    &__button {
      display: inline-flex;
      background: transparent !important;
      color: var(--black) !important;
      font-weight: bold;
      text-decoration: none;
      text-align: center;
      padding: var(--gap) calc(var(--gap) * 2);
      border: 3px solid var(--grey-dark);
      border-radius: var(--border-radius);
      outline: none;

      &:hover,
      &:focus {
        box-shadow: 0 0 0 2px var(--blue-dark);
      }
    }
  }

  &-embed {
    margin-top: var(--gap);
    margin-bottom: var(--gap);

    & > figcaption {
      font-size: var(--fz-sm);
      margin-top: var(--gap-half);
      margin-bottom: var(--gap);
      text-align: center;
    }

    &.wp-embed-aspect-16-9 > .wp-block-embed__wrapper {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%; /*16:9*/

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &-table {
    margin-top: var(--gap-double);
    margin-bottom: var(--gap-double);
    overflow: hidden;

    & > table {
      min-width: 600px;
      border-collapse: collapse;
      border-spacing: 0;
      border: 1px solid var(--grey-light);

      thead tr {
        border-bottom: 3px solid var(--black);
      }

      th,
      td {
        padding: 0.5rem;
      }
    }

    & > figcaption {
      font-size: var(--fz-sm);
      margin-top: var(--gap-half);
      margin-bottom: var(--gap);
      text-align: center;
    }

    &.is-style-regular {
      & > table {
        tr td {
          border: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  &-cover {
    &__inner-container {
      max-width: var(--row-md);
      margin-left: auto;
      margin-right: auto;
    }
  }

  &-spacer {
    margin-left: auto;
    margin-right: auto;
  }
}
