.btn {
  --text-colour: var(--black);
  --bg-colour: transparent;
  --border-colour: var(--grey-dark);

  display: inline-flex;
  align-items: center;
  background: var(--bg-colour);
  color: var(--text-colour);
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  padding: var(--gap) calc(var(--gap) * 2);
  border: 2px solid var(--border-colour);
  border-radius: var(--border-radius);
  outline: none;
  line-height: 1;

  &:hover,
  &:focus {
    box-shadow: 0 0 0 1px var(--border-colour);
  }

  &--fill {
    --text-colour: var(--white);
    --bg-colour: var(--black);
    --border-colour: var(--black);
  }

  &--green {
    --text-colour: var(--white);
    --bg-colour: var(--green);
    --border-colour: var(--green);
  }
}

.theme-dark .btn {
  --text-colour: var(--white);
  --border-colour: var(--grey-mid);

  &.btn--fill {
    --text-colour: var(--black);
    --bg-colour: var(--white);
    --border-colour: var(--white);
  }
}
