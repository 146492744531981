.hero-block {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: stretch;
  position: relative;

  & > .wrapper {
    flex-grow: 1;
  }

  &__content {
    padding-top: calc(var(--gap-double) * 2.5);
    padding-bottom: calc(var(--gap-double) * 2.5);
    width: 100%;
  }

  &__title {
    margin-top: 0;
    margin-bottom: calc(var(--gap) * 1.5);
  }
  
  &__subtitle {
    margin-top: 0;
    margin-bottom: calc(var(--gap) * 2);
  }
  
  &__text {
    margin-top: 0;
    margin-bottom: calc(var(--gap) * 4);
  }

  &__buttons {
    margin-top: calc(var(--gap) * 4);
  }

  &__image {
    display: none;
  }

  &__take-a-look {
    position: absolute;
    bottom: var(--gap);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;

    & > span {
      color: var(--grey-mid);
      display: block;
      margin-bottom: var(--gap-half);
      .theme-dark & {
        color: var(--grey-light);
      }
    }

    & > svg {
      transform: rotate(270deg);
    }
  }

  &.theme-dark {
    color: var(--white);
  }

  @media (--md) {
    &__content {
      width: 900px;
      max-width: 50%;
    }

    &__image {
      position: absolute;
      display: block;
      bottom: 0;
      height: auto;
      max-height: 100%;
      right: 0;
      width: auto;
      max-width: 50%;
      object-fit: contain;

      &--cover {
        object-fit: cover;
        height: 100%;
        width: 50%;
      }
    }
  }
}
