:root {
    /* colours */
    --white: #FFFFFF;
    --black: #00344F;
    --grey-xlight: #ECEEF0;
    --grey-light: #D9DDE3;
    --grey-mid: #7F99A7;
    --grey-dark: #647D9A;
    --grey-xdark: #435771;

    --purple: #93569D;
    --pink: #DF4A9D;
    --red: #FE5558;
    --green: #00A876;
    --blue-dark: #4968B4;
    --blue-mid: #82A6D7;
    --blue-light: #34C2F1;
    --teal: #44B9C0;
    --orange: #FCAE00;
    --yellow: #FACB52;

    --theme: var(--blue-dark);
    --text-colour: var(--black);

    /* size */
    --gap: 1rem;
    --gap-half: calc(var(--gap) / 2);
    --gap-double: calc(var(--gap) * 2);
    --row: 82rem;
    --row-xl: 70rem;
    --row-lg: 60rem;
    --row-md: 46rem;
    --row-sm: 36rem;
    --row-xs: 24rem;

    /* font */
    --base-font-stack: "sofia-pro", "Sofia Pro", "Helvetica Neue", "Helvetica", sans-serif;
    --title-font-stack: "sofia-pro", "Sofia Pro", "Helvetica Neue", "Helvetica", sans-serif;

    --fz-h1: 2.25rem;
    --fz-h2: 1.725rem;
    --fz-h3: 1.25rem;
    --fz-h4: 1.175rem;
    --fz-h5: 1rem;
    --fz-base: 1rem;
    --fz-sm: 0.885rem;

    @media (--sm) {
        --fz-h1: 3.125rem;
        --fz-h2: 2.25rem;
        --fz-h3: 1.725rem;
        --fz-h4: 1.35rem;
        --fz-h5: 1.1rem;
    }

    /* misc */
    --border-radius: 1rem;
    --border-radius-sm: 0.6rem;

    --header-height: 104px;
}