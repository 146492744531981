.pagination {
  ul {
    text-align: center;
    margin-top: var(--gap-double);
  }

  li {
    display: inline-flex;
    vertical-align: middle;
    margin: var(--gap-half) 0;
  }

  &__link {
    text-decoration: none;
    font-weight: bold;
    width: 50px;
    height: 50px;
    border: 3px solid var(--grey-xlight);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    color: var(--black);
    border-radius: 50%;

    &:hover,
    &:focus {
      border-color: var(--blue-dark);
    }

    &--active {
      border-color: var(--blue-dark);

      &:hover,
      &:focus {
        box-shadow: none;
      }
    }

    &--disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  &__prev,
  &__next {
    background-color: transparent;
  }
}
