.career-card {
  &__wrap {
    list-style: none;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: calc(var(--gap-double) * 2.5);
  }

  margin: 0;
  width: 100%;
  background-color: var(--white);
  border-radius: var(--border-radius);
  padding: var(--gap-double);
  padding-bottom: var(--gap);

  &__title {
    margin-top: 0;
    margin-bottom: var(--gap-double);
  }

  &__description {
    margin-top: var(--gap-half);
    margin-bottom: calc(var(--gap-double) * 1.25);
  }

  @media (--sm) {
    padding: calc(var(--gap-double) * 1.25);
    padding-bottom: var(--gap-double);
    width: unset;

    &__wrap {
      grid-template-columns: 1fr 1fr;
    }
  }
}
