.three-col-generic {
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(var(--gap-double) * 2);

  &__item {
    margin: 0;
    width: 100%;
    align-self: stretch;

    .btn {
      margin-top: auto;
      align-self: flex-start;
    }
  }

  &__text {
    margin-bottom: calc(var(--gap) * 2.5);
  }

  @media (--sm) {
    grid-template-columns: 1fr 1fr 1fr;

    &__item {
      & > article {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }

  &--center {
    .three-col-generic__item {
      text-align: center;
      align-items: center;

      .btn {
        align-self: center;
      }
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .three-col-generic {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    & > * {
      margin-bottom: var(--gap);
      width: calc(33% - 2rem);
    }
  }
}
