.article-body,
.block-section {
  & > *,
  & > [class^="wp-block"] {
    max-width: var(--row-md);
    margin-left: auto;
    margin-right: auto;

    &.alignwide {
      max-width: var(--row);
    }

    &.alignfull {
      max-width: none;
    }
  }
}
