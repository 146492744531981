.three-col-team {
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(var(--gap-double) * 1.5);

  &__member {
    margin: 0;
    width: 100%;
    align-self: stretch;

    & > article {
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-bottom: 2rem;
    }
  }

  img {
    border-radius: 50%;
  }

  &__name {
    margin-top: var(--gap-half);
  }

  &__role {
    margin-top: 0;
    margin-bottom: var(--gap);
    color: var(--yellow);
    .theme-light & {
      color: var(--grey-dark);
    }
  }

  &__details {
    & > summary {
      list-style: none;
      cursor: pointer;
      text-decoration: underline;
      color: var(--blue-light);

      .theme-light & {
        color: var(--green);
      }

      &:hover,
      &:focus {
        text-decoration: none;
      }

      &::-webkit-details-marker {
        display: none;
      }
    }

    &[open] > summary {
      cursor: default;
    }

    &[open] > summary:before {
      content: " ";
      background: rgba(0, 0, 0, 0.5);
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 12;
    }

    details-dialog {
      position: fixed;
      margin: 8vh auto;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 14;
      max-height: 84vh;
      width: 90vw;
      max-width: var(--row--md);
      background-color: var(--white);
      padding: var(--gap-double);
      border-radius: var(--border-radius);
      color: var(--black);
      overflow: auto;

      .three-col-team {
        &__role {
          color: var(--grey-dark);
        }
      }

      button {
        position: absolute;
        top: var(--gap);
        right: var(--gap);
        background: none;
        border: none;
        appearance: none;
        color: var(--grey-mid);
        cursor: pointer;

        svg {
          display: block;
        }
      }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      details-dialog {
        display: none;
      }
      & > summary:focus + details-dialog {
        display: block;
      }
    }
  }

  @media (--sm) {
    grid-template-columns: 1fr 1fr 1fr;

    details-dialog {
      width: var(--row-md);
      padding: calc(var(--gap-double) * 2);
    }
  }
}
