.post-preview {
  border-radius: var(--border-radius);
  overflow: hidden;
  text-decoration: none;
  background: var(--white);
  display: flex;
  flex-direction: column;
  position: relative;

  &__image-holder {
    background: var(--blue-dark);
    position: relative;
  }

  &__image {
    display: block;
    width: 100%;
  }

  &__tag {
    position: absolute;
    bottom: 3px;
    left: 3px;
    display: inline-flex;
    background-color: white;

    span {
      background-color: var(--blue-dark);
      padding: 0.2rem 1rem;
    }
  }

  &__content {
    padding: var(--gap);
    white-space: normal;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border: 3px solid var(--blue-dark);
    border-top: none;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }

  &__title {
    font-size: var(--fz-h5);
    font-family: var(--title-font-stack);
    margin-bottom: var(--gap-half);
    text-align: left;
  }

  &__posted {
    text-align: left;
    margin-bottom: var(--gap-double);
    font-style: italic;
  }

  .btn {
    margin-top: auto;
    text-align: center;
    justify-content: center;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2);

    .btn {
        text-decoration: underline;
    }
  }
}
