.wrapper {
  max-width: var(--row);
  margin-left: auto;
  margin-right: auto;

  &-xl {
    max-width: var(--row-xl);
  }

  &-lg {
    max-width: var(--row-lg);
  }

  &-md {
    max-width: var(--row-md);
  }

  &-sm {
    max-width: var(--row-sm);
  }

  &-xs {
    max-width: var(--row-xs);
  }
}