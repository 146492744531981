.text {
  &-theme {
    color: var(--blue-dark);
  }
  &-white {
    color: var(--white);
  }
  &-black {
    color: var(--black);
  }
  &-grey-light {
    color: var(--grey-light);
  }
  &-grey-mid {
    color: var(--grey-mid);
  }
  &-grey-dark {
    color: var(--grey-dark);
  }
}

.bg {
  &-theme {
    background-color: var(--blue-dark);
  }
  &-white {
    background-color: var(--white);
  }
  &-black {
    background-color: var(--black);
  }
  &-grey-xlight {
    background-color: var(--grey-xlight);
  }
  &-grey-light {
    background-color: var(--grey-light);
  }
  &-grey-mid {
    background-color: var(--grey-mid);
  }
  &-grey-dark {
    background-color: var(--grey-dark);
  }
  &-grey-xdark {
    background-color: var(--grey-xdark);
  }
  &-purple {
    background-color: var(--purple);
  }
  &-pink {
    background-color: var(--pink);
  }
  &-red {
    background-color: var(--red);
  }
  &-green {
    background-color: var(--green);
  }
  &-blue-dark {
    background-color: var(--blue-dark);
  }
  &-blue-mid {
    background-color: var(--blue-mid);
  }
  &-blue-light {
    background-color: var(--blue-light);
  }
  &-teal {
    background-color: var(--teal);
  }
  &-orange {
    background-color: var(--orange);
  }
  &-yellow {
    background-color: var(--yellow);
  }
}
