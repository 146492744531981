.article-content,
.article-content > div,
.block-section {
  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > h5,
  & > h6 {
    margin-top: var(--gap);
    margin-bottom: var(--gap);
    font-weight: 400;
  }

  & > ul:not([class]),
  & > ol:not([class]) {
    list-style: revert;
    padding-inline-start: var(--gap-double);
    margin-bottom: var(--gap);

    li {
      margin: 0.2rem 0;
      text-align: inherit;
    }

    ul:not([class]),
    ol:not([class]) {
      list-style: revert;
      padding-inline-start: var(--gap-double);
      margin-bottom: var(--gap);

      li {
        margin: 0.2rem 0;
        text-align: inherit;
      }
    }
  }

  & > p {
    margin-bottom: 1rem;
  }

  a:not([class]) {
    text-decoration: underline;
    color: currentColor;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  & > h1 {
    font-size: var(--fz-h1);
  }
  & > h2 {
    font-size: var(--fz-h2);
  }
  & > h3 {
    font-size: var(--fz-h3);
  }
  & > h4 {
    font-size: var(--fz-h4);
  }
  & > h5 {
    font-size: var(--fz-h5);
  }
  & > h6 {
    font-size: var(--fz-base);
  }

  hr {
    border-color: var(--grey-mid);
  }

  & > *:not(.block-section, .fullwidth, ul, ol, img, .alignfull) {
    padding-left: var(--gap);
    padding-right: var(--gap);
  }

  .gallery {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    gap: var(--gap);
  }

  .gallery .gallery-item {
    position: relative;
    text-align: center;
    flex: 1 1 calc(33% - var(--gap));
  }

  .gallery .gallery-item img {
    height: auto;
  }

  .gallery-columns-2 .gallery-item {
    flex: 1 1 calc(50% - var(--gap));
  }

  .gallery-columns-4 .gallery-item {
    flex: 0 1 calc(25% - var(--gap));
  }

  .gallery-columns-2 .attachment-medium {
    max-width: 92%;
    height: auto;
  }

  .gallery-columns-4 .attachment-thumbnail {
    max-width: 84%;
    height: auto;
  }

  .gallery .gallery-caption {
    margin: 0.2rem 0 0.6rem 0;
    display: block;
  }

  .gallery dl,
  .gallery dt {
    margin: 0;
  }

  .gallery br + br {
    display: none;
  }

  .alignleft,
  img.alignleft {
    margin: 0 auto;
    float: none;
    display: block;

    @media (--sm) {
      display: inline;
      float: left;
      margin: 0 20px 0 0;
      padding: 0 0 20px 0;
    }
  }

  .alignright,
  img.alignright {
    margin: 0 auto;
    float: none;
    display: block;

    @media (--sm) {
      display: inline;
      float: right;
      margin: 0 0 0 20px;
      padding: 0 0 20px 0;
    }
  }

  table {
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin: 0 0 20px 0;
    border: none;

    thead td {
      color: var(--white);
      border: 0;
      padding: 14px 24px 14px 14px;
      font-weight: 400;
    }

    thead tr,
    thead {
      background-color: var(--grey-xdark);
    }

    tbody tr {
      border: 0;
      background-color: var(--grey-xlight);

      td {
        border: 0;
        padding: 14px 14px 14px 24px;
        border-top: 1px solid var(--grey-light);
        color: var(--black);
      }
    }

    a {
      color: var(--black);
      text-decoration: underline;
    }
  }

  .wp-block {
    &-cover {
      &.is-light {
        .wp-block-cover__inner-container {
          color: var(--white);
        }
      }
    }
  }
}
