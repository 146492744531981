/* some css is baked in, this is extra */
#cookieconsent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--black);
  z-index: 10;

  .cc {
    &-grower {
      max-height: unset !important;
    }

    &-banner,
    &-floating {
      padding: var(--gap-half) var(--gap);
      max-width: var(--row-lg);
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      & > * {
        margin-top: var(--gap-half);
        margin-bottom: var(--gap-half);
      }
    }

    &-compliance {
      flex-grow: 1;
      flex-shrink: 0;
    }

    &-btn {
      margin-left: var(--gap);
      margin-right: var(--gap);
      display: inline-flex;
      cursor: pointer;
    }

    &-deny {
      text-decoration: underline;
    }

    &-allow {
      border: 2px solid var(--white);
      border-radius: var(--border-radius);
      padding: var(--gap-half) var(--gap);
    }

    @media (--tiny) {
      &-btn {
        margin-left: var(--gap-double);
        margin-right: var(--gap-double);
      }
    }

    @media (--sm) {
      &-banner,
      &-floating {
        flex-direction: row;
      }

      &-message {
        flex: 0 1 var(--row-md);
      }

      &-compliance {
        flex-grow: 1;
        flex-shrink: 0;
        margin-left: var(--gap-double);
      }

      &-btn {
        margin-right: 0;
      }
    }
  }
}
