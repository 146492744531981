.footer {
  background-color: var(--white);
  text-align: center;

  address {
    font-style: normal;
  }

  &__socials {
    display: flex;
    justify-content: center;
  }
}
