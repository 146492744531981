.block-section {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: calc(var(--gap-double) * 2.5);
  padding-bottom: calc(var(--gap-double) * 2.5);

  &--fixed {
    background-attachment: fixed;
  }

  &.theme-dark {
    color: var(--white);
  }

  &--bg-image {
    @media (--sm) {
      background-image: var(--bg-image);
    }

    @media (--lg) {
      background-image: var(--bg-image-lg);
    }
  }
}

.one-col-content {
  display: flow-root;
}