.header {
  background-color: #FFF;
  position: fixed;
  z-index: 12;
  top: 0;
  left: 0;
  height: var(--header-height);
  width: 100%;

  .admin-bar & {
    top: 32px;
  }

  & > .wrapper {
    padding: 0 var(--gap-double);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__home-link {
    height: var(--header-height);
    display: flex;
    align-items: center;
    margin-right: var(--gap-double);
    flex-shrink: 0;
  }

  &__nav-toggle,
  &__nav-close {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    background: none;
    border: none;
  }

  &__nav-toggle {
    margin-left: var(--gap);
    padding: var(--gap-half) 0;
    color: var(--black);

    &[aria-expanded="true"] {
      & + .header__nav {
        display: block;
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 0 1 840px;
    margin-left: var(--gap);
  }

  &__nav-close {
    display: none;
  }

  @media (--md) {
    &__nav {
      display: block;
      order: 0;
      width: auto;
      padding-top: 0;
      border: none;
    }

    &__nav-toggle,
    &__nav-close {
      display: none;
    }
  }

  @media (--md-max) {
    &__nav {
      position: fixed;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100vh;
      z-index: 12;
      transform: translateX(0);
      transition: transform 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      padding-right: 4.5rem;
      margin-left: 0;
      background: var(--white);
    }

    &__nav-close {
      width: 4.5rem;
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      height: 100%;
      background: var(--grey-xlight);
      padding: var(--gap) 0 0;
      color: var(--black);

      span {
        height: 100%;
        align-items: flex-start;
        display: flex;
        justify-content: center;
      }
    }

    &__nav-toggle[aria-expanded="true"] + .header__nav {
      transform: translateX(100%);

      .header__nav-close {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}
